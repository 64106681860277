import { AIModel } from "types/ai-assistant.model";

export const config = {
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL
};

export const TOKEN_ACCESS = "access.token";
export const TOKEN_REFRESH = "refresh.token";
export const METADATA_JSON = "metadata";
export const CURRENT_USER = "current.user";
export const STATEMENTS_SETTINGS = {
  HIDE_ALL_QUARTERS: "hide.all.quarters",
  CURRENCY: "currency",
  DECIMAL: "decimal",
  UNIT: "unit",
  FROM: "from",
  TO: "to"
};

export const DEBOUNCE_TIME = 400;

export const LANGUAGES = [
  {
    title: "English",
    value: "en"
  },
  {
    title: "繁體中文",
    value: "zh"
  }
];

export const HCAPTCHA_SITE_KEY = "8ed3b1b0-e725-4953-9643-819dfd877e45";

export const LANDING_PAGE_URL = "https://www.prjanalytics.net/";
export const TERM_OF_USE_URL = "https://www.prjanalytics.net/terms-of-service";
export const PRIVACY_POLICY_URL = "https://www.prjanalytics.net/privacy-policy";
export const DISCLAIMER_URL = "https://www.prjanalytics.net/site-disclaimer";
export const ACCEPTABLE_USE_POLICY_URL =
  "https://www.prjanalytics.net/publish-use-policy";

export const EMPTY_CELL = "—";

export const APP_VERSION = "1.16.1";

export const DATE_FORMAT = "YYYY/MM/DD";

export const FREE_PLAN = "Free Plan";

export const CHINESE_ADDITIONAL_PROMPT = "用台灣繁體中文回覆。";

export const AI_MODEL_LIST = [
  {
    label: AIModel.GPT_4O,
    value: AIModel.GPT_4O
  },
  {
    label: AIModel.SONAR,
    value: AIModel.SONAR
  }
];
